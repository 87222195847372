export const formatPhoneNumber = (phone) => {
  if (phone) {
    let number = phone.replace(/\D/g, '').replace(/\s/g, '');
    let formattedNumber = '';

    if (number.substring(0, 1) === '1') {
      number = number.substring(1);
    }
    if (number.length > 0) {
      formattedNumber += `(${number.substring(0, 3)}`;
    }
    if (number.length > 3) {
      formattedNumber += `) ${number.substring(3, 6)}`;
    }
    if (number.length > 6) {
      formattedNumber += `-${number.substring(6, 10)}`;
    }

    return formattedNumber;
  }
  return '';
};

function reverse(str) {
  return str.split('').reverse().join('');
}

export const formatCurrency = (currency) => {
  function reverse(str) {
    return str.split('').reverse().join('');
  }

  if (!currency) {
    return '';
  }

  const numbers = currency.toString().split('.');
  let dollars = numbers[0].replace(/\D/g, '');
  let cents = numbers[1];

  if (cents) {
    cents = cents.slice(0, 2);
  }

  if (dollars.length > 4) {
    dollars = reverse(reverse(dollars).match(/.{1,3}/g).join(','));
  }

  if (typeof cents === 'string') {
    return `$ ${dollars}.${cents.slice(0.2)}`;
  }

  return `$ ${dollars}`;
};

export const formatCurrencyWithCents = (currency) => {
  if (currency) {
    const split = currency.toString().split('.');

    let dollar = split[0].replace(/\D/g, '');
    let cents = split[1] ? split[1].substring(0, 2) : '00';

    if (dollar.length > 4) {
      dollar = reverse(reverse(dollar).match(/.{1,3}/g).join(','));
    }

    return `$ ${[dollar, cents].join('.')}`;
  }

  return '';
};

export const formatNumber = (string) => {
  if (string) {
    return string.replace(/\D/g, '');
  }
  return '';
};
