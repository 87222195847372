const Fetch = {
  get: async ({ url, params }) => {
    try {
      function encodeDataToURL(data){
        return Object
          .keys(data)
          .map(value => `${value}=${encodeURIComponent(data[value])}`)
          .join('&');
      }

      if (params) {
        url += `?${encodeDataToURL(params)}`
      }

      const request = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      });

      const response = await request.json();

      return response;
    } catch (error) {
      console.warn(error);
      return { serverErrors: error };
    }
  },

  post: async ({ url, payload = {} }) => {
    try {
      const request = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(payload),
      });

      const response = await request.json();

      return response;
    } catch (error) {
      console.warn(error);
      return { serverErrors: error };
    }
  },

  puts: async ({ url, payload = {} }) => {
    try {
      const request = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
        body: JSON.stringify(payload),
      });

      const response = await request.json();

      return response;
    } catch (error) {
      console.warn(error);
      return { serverErrors: error };
    }
  },
};

export default Fetch;
