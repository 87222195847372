import React, { useState, useEffect } from 'react';

import { StatusSelector, Message, Footer, Errors } from './functional';
import DynamicMatchFields from './DynamicMatchFields';
import FollowUp from './FollowUp';
import ZeroReferralInterruptor from './ZeroReferralInterruptor';
import ClosingsCollectables from './ClosingsCollectables';

const MatchModalBody = props => {
  const {
    allowClosingsCollectables,
    currentUser,
    errors,
    note,
    popup,
    task,
    match,
    lead: {
      hasZeroReferralFee,
      existingMatchId,
    },
    additionalInfo,
    checkDetails,
    closingInfo,
    close,
    closingsCollectables,
    contact,
    flywheelUserOptions,
    clientFinancing,
    referralForm,
    sendEmail,
    submit,
    toggleHeader,
    updateAdditionalInfo,
    updateAssignedTo,
    updateFollowUp,
    updateMatchState,
    updateNoteState,
    updateStateByName,
    updateTaskState,
    updateClosingInfo,
    updateClosingsCollectables,
    updateCheckDetails,
  } = props;

  const [showInterruptor, setShowInterruptor] = useState(0);

  useEffect(handleShowDisruptor, []);

  function handleShowDisruptor() {
    if (hasZeroReferralFee && popup.popupType !== 'kill') {
      setShowInterruptor(1);
      toggleHeader();
    }
  }

  if (showInterruptor) {
    return (
      <ZeroReferralInterruptor
        noteable="match"
        existingMatchId={existingMatchId}
        setShowInterruptor={setShowInterruptor}
        toggleHeader={toggleHeader}
        close={close}
      />
    )
    } else if (popup.popupType == 'remove_match') {
    return(
      <div className='modal-body'>
        <Errors errors={errors} />
        <Message
          prompt='Please explain why this Match is being removed:'
          disableInput={popup.disableInput}
          value={note.message}
          updateNoteState={updateNoteState}
          />
        <Footer
          disableInput={popup.disableInput}
          close={close}
          submit={submit}
          popupType='remove_match'
        />
      </div>
    )
  } else {
    return(
      <div className='modal-body'>
        <Errors errors={errors} />
        <StatusSelector
          disableInput={popup.disableInput}
          value={note.status}
          updateNoteState={updateNoteState}
          noteStatusOptions={popup.noteStatusOptions}
        />
        <DynamicMatchFields
          note={note}
          match={match}
          contact={contact}
          sendEmail={sendEmail}
          closingInfo={closingInfo}
          checkDetails={checkDetails}
          referralForm={referralForm}
          additionalInfo={additionalInfo}
          clientFinancing={clientFinancing}
          disableInput={popup.disableInput}
          updateMatchState={updateMatchState}
          updateStateByName={updateStateByName}
          updateClosingInfo={updateClosingInfo}
          updateCheckDetails={updateCheckDetails}
          updateAdditionalInfo={updateAdditionalInfo}
          mortgageLenderOptions={popup.mortgageLenderOptions}
        />
        <Message
          disableInput={popup.disableInput}
          value={note.message}
          updateNoteState={updateNoteState}
          />
        <FollowUp
          disableInput={popup.disableInput}
          task={task}
          updateTaskState={updateTaskState}
          flywheelUserOptions={flywheelUserOptions}
          updateFollowUp={updateFollowUp}
          updateAssignedTo={updateAssignedTo}
          currentUserId={currentUser.id}
        />
        {
          allowClosingsCollectables && (
            <>
              <br />
              <ClosingsCollectables
                disableInput={popup.disableInput}
                match={match}
                closingsCollectables={closingsCollectables}
                onChange={updateClosingsCollectables}
              />
            </>
          )
        }
        <Footer
          disableInput={popup.disableInput}
          close={close}
          submit={submit}
          task={task}
        />
      </div>
    )
  }
}

export default MatchModalBody;
