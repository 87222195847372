import React, { useState, useEffect } from 'react';

import { StatusSelector, Message, Footer, Errors } from './functional';
import FollowUp from './FollowUp';
import ZeroReferralInterruptor from './ZeroReferralInterruptor';

const LeadModalBody = (props) => {
  const {
    currentUser,
    errors,
    note,
    popup,
    task,
    lead: {
      hasZeroReferralFee,
      existingMatchId,
    },
    close,
    submit,
    toggleHeader,
    loadKillPopup,
    sendRequalEmail,
    updateAssignedTo,
    updateFollowUp,
    updateNoteState,
    updateTaskState,
    updateSendRequalEmail,
  } = props;

  const [showInterruptor, setShowInterruptor] = useState(0);

  useEffect(handleShowDisruptor, []);

  function handleShowDisruptor() {
    if (hasZeroReferralFee && popup.popupType !== 'kill') {
      setShowInterruptor(1);
      toggleHeader();
    }
  }

  if (showInterruptor) {
    return (
      <ZeroReferralInterruptor
        noteable="lead"
        existingMatchId={existingMatchId}
        setShowInterruptor={setShowInterruptor}
        loadKillPopup={loadKillPopup}
        toggleHeader={toggleHeader}
        close={close}
      />
    )
  } else if (popup.popupType == 'kill') {
    return(
      <div className='modal-body'>
        <Errors errors={errors} />
        <StatusSelector
          disableInput={popup.disableInput}
          value={note.status}
          updateNoteState={updateNoteState}
          noteStatusOptions={popup.noteStatusOptions}
        />
        <Message
          disableInput={popup.disableInput}
          value={note.message}
          updateNoteState={updateNoteState}
        />
        <Footer
          disableInput={popup.disableInput}
          close={close}
          submit={submit}
          popupType='kill'
          updateSendRequalEmail={updateSendRequalEmail}
          sendRequalEmail={sendRequalEmail}
        />
      </div>
    )
  } else {
    return(
      <div className='modal-body'>
        <Errors errors={errors} />
        <StatusSelector
          disableInput={popup.disableInput}
          value={note.status}
          updateNoteState={updateNoteState}
          noteStatusOptions={popup.noteStatusOptions}
        />
        <Message
          disableInput={popup.disableInput}
          value={note.message}
          updateNoteState={updateNoteState}
        />
        <FollowUp
          disableInput={popup.disableInput}
          task={task}
          updateTaskState={updateTaskState}
          flywheelUserOptions={popup.flywheelUserOptions}
          updateFollowUp={updateFollowUp}
          updateAssignedTo={updateAssignedTo}
          currentUserId={currentUser.id}
        />
        <Footer
          disableInput={popup.disableInput}
          close={close}
          submit={submit}
          task={task}
        />
      </div>
    )
  }
}

export default LeadModalBody;
