class CloseDateField extends React.Component {
  constructor(props) {
    super(props);

    this.updateMatchAgentClosedAt = this.updateMatchAgentClosedAt.bind(this);
  }

  updateMatchAgentClosedAt(dateString) {
    const dummyEvent = { currentTarget: { name: 'agentClosedAt', value: dateString } }
    this.props.updateMatchState(dummyEvent);
  }

  componentDidMount() {
    $('#match_agent_closed_at').attachDatePickerNoLimit(this.updateMatchAgentClosedAt);
  }

  render() {
    return(
      <span className='dynamic_field_holder relative'>
        <b>Close Date: </b>
        <input
          type="text"
          name="agentClosedAt"
          id="match_agent_closed_at"
          value={this.props.value}
          onChange={this.props.updateMatchState}
          autoComplete="off"
        />
      </span>
    )
  }
}

class ListingDateField extends React.Component {
  constructor(props) {
    super(props);

    this.updateAgentListedAt = this.updateAgentListedAt.bind(this);
  }

  updateAgentListedAt(dateString) {
    const dummyEvent = { currentTarget: { name: 'agentListedAt', value: dateString } }
    this.props.updateMatchState(dummyEvent);
  }

  componentDidMount() {
    $('#match_agent_listed_at').attachDatePickerNoLimit(this.updateAgentListedAt);
  }

  render() {
    return(
      <span className='dynamic_field_holder relative'>
        <b>Listing Date: </b>
        <input
          type="text"
          name="agentListedAt"
          id="match_agent_listed_at"
          value={this.props.value}
          onChange={this.props.updateMatchState}
          autoComplete="off"
        />
      </span>
    )
  }
}

class SoldRemovedField extends React.Component {
  constructor(props) {
    super(props);

    this.updateSoldRemoved = this.updateSoldRemoved.bind(this);
  }

  updateSoldRemoved(dateString) {
    const dummyEvent = { currentTarget: { name: 'soldRemovedAt', value: dateString } }
    this.props.updateAdditionalInfo(dummyEvent);
  }

  componentDidMount() {
    $('#sold_removed_at').attachDatePickerNoLimit(this.updateSoldRemoved);
  }

  render() {
    return(
      <span className='dynamic_field_holder relative'>
        <b>Sold/Removed: </b>
        <input
          type='text'
          name='soldRemovedAt'
          id='sold_removed_at'
          value={this.props.value}
          onChange={this.props.updateAdditionalInfo}
          autoComplete='off'
        />
      </span>
    )
  }
}

class CheckDateField extends React.Component {
  constructor(props) {
    super(props);

    this.updateCheckDate = this.updateCheckDate.bind(this);
  }

  updateCheckDate(dateString) {
    const dummyEvent = { currentTarget: { name: 'checkDate', value: dateString } }
    this.props.updateCheckDetails(dummyEvent);
  }

  componentDidMount() {
    $('#check_details_check_date').attachDatePickerNoLimit(this.updateCheckDate);
  }

  render() {
    return(
      <span className='dynamic_field_holder relative'>
        <b>Check Date: </b>
        <input
          type="text"
          name="checkDate"
          className='check_details'
          id="check_details_check_date"
          value={this.props.value}
          onChange={this.props.updateCheckDetails}
          autoComplete="off"
        />
      </span>
    )
  }
}

export { CloseDateField, ListingDateField, SoldRemovedField, CheckDateField };
