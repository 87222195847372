import { CloseDateField, ListingDateField, SoldRemovedField, CheckDateField } from './DatePickerFields';

const DynamicMatchFields = props => {
  const dynamicFields = function() {
    const purchasePropertyAddress = (
      <span className='dynamic_field_holder'>
        <b>Property Address: </b>
        <input
          type="text"
          name="purchasePropertyAddress"
          id="match_purchase_property_address"
          value={props.match.purchasePropertyAddress}
          onChange={props.updateMatchState}
          autoComplete="off"
        />
      </span>
    );
    const commission = (
      <span className='dynamic_field_holder relative'>
        <b>Commission: </b>
        <input
          type="text"
          id="match_agent_commission"
          name="agentCommission"
          value={props.match.agentCommission}
          onChange={e => props.updateMatchState(e, { input: 'number', maxLength: 5 })}
          autoComplete="off"
        />
        <span className='percentage'>%</span>
      </span>
    );
    const closeDate = (
      <CloseDateField
        value={props.match.agentClosedAt}
        updateMatchState={props.updateMatchState}
      />
    );
    const checkNumber = (
      <span className='dynamic_field_holder'>
        <b>Check Number: </b>
        <input
          type="text"
          name="checkNumber"
          className='check_details'
          id="check_details_check_number"
          value={props.checkDetails.checkNumber}
          onChange={e => props.updateCheckDetails(e, { maxLength: 10 })}
          autoComplete="off"
        />
      </span>
    );
    const checkAmount = (
      <span className='dynamic_field_holder'>
        <b>Check Amount: </b>
        <input
          type="text"
          name="checkAmount"
          className='check_details'
          id="check_details_check_amount"
          value={props.checkDetails.checkAmount}
          onChange={e => props.updateCheckDetails(e, { input: 'number' })}
          autoComplete="off"
        />
      </span>
    );
    const checkDate = (
      <CheckDateField
        value={props.checkDetails.checkDate}
        updateCheckDetails={props.updateCheckDetails}
      />
    );
    const listingDate = (
      <ListingDateField
        value={props.match.agentListedAt}
        updateMatchState={props.updateMatchState}
      />
    );
    const listingPrice = (
      <span className='dynamic_field_holder'>
        <b>Listing Price: </b>
        $
        <input
          type="text"
          name="listingPrice"
          id="match_listing_price"
          value={props.match.listingPrice}
          onChange={e => props.updateMatchState(e, { input: 'number' })}
          autoComplete="off"
        />
      </span>
    );
    const listingLinks = (
      <span className='dynamic_field_holder'>
        <b>Listing Link(s): </b>
        <input
          type='text'
          name='listingLinks'
          id='listing_links'
          value={props.additionalInfo.listingLinks}
          onChange={props.updateAdditionalInfo}
          autoComplete='off'
        />
      </span>
    );
    const listingStatusOptions = ['Listed', 'In Escrow', 'Sold', 'Delisted', 'Lost it'];
    const listingStatus = (
      <span className='dynamic_field_holder'>
        <b>Listing Status: </b>
        <select
          name='listingStatus'
          id='listing_status'
          value={props.additionalInfo.listingStatus}
          onChange={props.updateAdditionalInfo}
        >
          <option value='' key='_'></option>
          {
            listingStatusOptions.map((val, index) => {
              return(
                <option value={val} key={index}>
                  {val}
                </option>
              )
            })
          }
        </select>
      </span>
    );
    const listingAgent = (
      <span className='dynamic_field_holder'>
        <b>Listing Agent: </b>
        <input
          type='text'
          name='listingAgent'
          id='listing_agent'
          value={props.additionalInfo.listingAgent}
          onChange={props.updateAdditionalInfo}
          autoComplete='off'
        />
      </span>
    );
    const mlsNumber = (
      <span className='dynamic_field_holder'>
        <b>MLS #: </b>
        <input
          type="text"
          name="mlsNumber"
          id="match_mls_number"
          value={props.match.mlsNumber}
          onChange={props.updateMatchState}
          autoComplete="off"
        />
      </span>
    );
    const soldRemovedAt = (
      <SoldRemovedField
        value={props.additionalInfo.soldRemovedAt}
        updateAdditionalInfo={props.updateAdditionalInfo}
      />
    );
    const soldPrice = (
      <span className='dynamic_field_holder'>
        <b>Sold Price: </b>
        <input
          type='text'
          name='soldPrice'
          id='sold_price'
          value={props.additionalInfo.soldPrice}
          onChange={props.updateAdditionalInfo}
          autoComplete='off'
        />
      </span>
    );

    const mortgageLender = (
      <span className='dynamic_field_holder'>
        <b>Mortgage Lender: </b>
        <input
          type="text"
          name="mortgageLender"
          list="mortgageLenderOptions"
          id="mortgage_lender"
          value={props.closingInfo.mortgageLender}
          onChange={e => props.updateClosingInfo(e, { maxLength: 255 })}
          autoComplete="off"
        />

        <datalist
          id="mortgageLenderOptions"
        >
          {
            props.mortgageLenderOptions.map((val, index) => {
              return(
                <option value={val} key={index}>
                  {val}
                </option>
              )
            })
          }
        </datalist>

      </span>
    )

    const loanOfficer = (
      <span className='dynamic_field_holder'>
        <b>Loan Officer: </b>
        <input
          type="text"
          id="match_loan_officer"
          name="loanOfficer"
          value={props.closingInfo.loanOfficer}
          onChange={e => props.updateClosingInfo(e, { maxLength: 255 })}
          autoComplete="off"
        />
      </span>
    )

    const loanAmount = (
      <span className='dynamic_field_holder'>
        <b>Loan Amount: </b>
        <input
          type="text"
          id="match_loan_amount"
          name="loanAmount"
          value={props.closingInfo.loanAmount}
          onChange={e => props.updateClosingInfo(e, { input: 'number' })}
          autoComplete="off"
        />
      </span>
    )

    const interestRate = (
      <span className='dynamic_field_holder relative'>
        <b>Interest Rate: </b>
        <input
          type="text"
          id="match_interest_rate"
          name="interestRate"
          value={props.closingInfo.interestRate}
          onChange={e => props.updateClosingInfo(e, { input: 'number' })}
          autoComplete="off"
        />
        <span className='percentage'>%</span>
      </span>
    )

    const checkInfo = [
      checkNumber,
      checkAmount,
      checkDate
    ];
    const escrow = [
      commission,
      closeDate,
      ...checkInfo
    ];
    const loanInfo = [
      mortgageLender,
      loanOfficer,
      loanAmount,
      interestRate
    ];
    const buyerEscrow = [
      listingLinks,
      mlsNumber
    ];
    const sellerDefaults = [
      listingStatus,
      listingAgent,
      listingLinks,
      soldRemovedAt,
      mlsNumber
    ];
    const clientStatusFields = {
      'seller': {
        'Is Client - Listed/Showing': [
          commission,
          listingDate,
          listingPrice,
          ...sellerDefaults
        ],
        'Client - Working Relationship': [
          commission,
          listingDate,
          listingPrice,
          ...sellerDefaults
        ],
        'Is Client - Making Offers': [
          commission
        ],
        'Is Client - Escrow': [
          ...escrow,
          ...sellerDefaults,
          ...loanInfo
        ],
        'Client - Escrow': [
          ...escrow,
          ...sellerDefaults,
          ...loanInfo
        ],
        'Is Client - Closed Escrow': [
          ...escrow,
          ...sellerDefaults,
          ...loanInfo
        ],
        'Client - Closed Escrow': [
          ...escrow,
          ...sellerDefaults,
          ...loanInfo
        ],
        'Client - Working Relationship Terminated': [
          ...sellerDefaults
        ],
        'Is Client - Expired/Withdrawn': sellerDefaults,
        'Is Client - Lost it, Active': sellerDefaults,
        'Client - Lost it - Active': sellerDefaults,
        'Is Client - Lost it, Closed': sellerDefaults,
        'Client - Lost it - Closed': sellerDefaults,
        'Client - Offer Submitted': [
          commission,
          purchasePropertyAddress,
          listingPrice,
          mlsNumber,
          listingLinks,
          closeDate,
          ...loanInfo
        ],
        'Client - Check Received': [
          commission,
          closeDate,
          ...checkInfo,
          ...sellerDefaults,
          ...loanInfo
        ]
      },
      'buyer': {
        'Is Client - Listed/Showing': [
          commission,
          closeDate,
          listingPrice,
          listingLinks,
          mlsNumber,
          purchasePropertyAddress
        ],
        'Client - Working Relationship': [
          commission,
          purchasePropertyAddress,
          listingPrice,
          listingLinks,
          mlsNumber,
          closeDate
        ],
        'Is Client - Making Offers': [
          commission
        ],
        'Is Client - Escrow': [
          ...escrow,
          ...buyerEscrow,
          purchasePropertyAddress,
          listingPrice,
          ...loanInfo
        ],
        'Client - Escrow': [
          ...escrow,
          ...buyerEscrow,
          purchasePropertyAddress,
          listingPrice,
          ...loanInfo
        ],
        'Is Client - Closed Escrow': [
          ...escrow,
          ...buyerEscrow,
          purchasePropertyAddress,
          soldPrice,
          ...loanInfo
        ],
        'Client - Closed Escrow': [
          ...escrow,
          ...buyerEscrow,
          purchasePropertyAddress,
          soldPrice,
          ...loanInfo
        ],
        'Client - Working Relationship Terminated': [],
        'Is Client - Expired/Withdrawn': [],
        'Is Client - Lost it, Active': [],
        'Client - Lost it - Active': [],
        'Is Client - Lost it, Closed': [],
        'Client - Lost it - Closed': [],
        'Client - Offer Submitted': [
          commission,
          purchasePropertyAddress,
          listingPrice,
          mlsNumber,
          listingLinks,
          closeDate,
          ...loanInfo
        ],
        'Client - Check Received': [
          commission,
          closeDate,
          ...checkInfo,
          purchasePropertyAddress,
          soldPrice,
          mlsNumber,
          listingLinks,
          ...loanInfo
        ],
        'Client - Check Sent / Closed': [
          commission,
          closeDate,
          ...checkInfo,
          purchasePropertyAddress,
          soldPrice,
          mlsNumber,
          listingLinks,
          ...loanInfo
        ]
      }
    }

    let fields = [];
    if (!!Object.keys(props.match).length) {
      fields = clientStatusFields[props.match.leadType][props.note.status] || [];
    }

    const htmlFields = [];
    for(let i = 0; i < fields.length; i+=2) {
      htmlFields.push(
        <div className='dynamic_field_row'>
          {fields[i]}
          {fields[i+1]}
        </div>
      )
    }

    return htmlFields;
  }

  const contactAndEmailFields = function() {
    let fields = null;
    if (props.note.status.match(/client -/i)) {
      const contactOptions = {
        'Contact': [
          'Received Email',
          'Received Voicemail',
          'Spoke with agent'
        ],
        'No Contact': [
          'Left Voicemail',
          'Unable to Leave Voicemail',
          'Contact Not Needed'
        ]
      }
      const emailOptions = {
        'sendAgentFollowUp':  'Agent Follow-up',
        'sendReferralForm':   'Payment Form - Listing',
        'referralFormAllRex': 'Payment Form - All Closing Docs'
      };

      fields = (
        <div className='contact_and_email_fields'>
          <select
            name="contact"
            value={props.contact}
            disabled={props.disableInput}
            onChange={props.updateStateByName}
          >
            <option value={''} key='_'>Contact</option>
            {
              Object.keys(contactOptions).map((key, collectionIndex) => {
                return(
                  <optgroup label={key} key={key}>
                    {
                      contactOptions[key].map((val, index) => {
                        return(
                          <option value={val} key={collectionIndex + '' + index}>
                            {val}
                          </option>
                        )
                      })
                    }
                  </optgroup>
                )
              })
            }
          </select>

          <select
            name="sendEmail"
            value={props.sendEmail}
            disabled={props.disableInput}
            onChange={props.updateStateByName}
          >
            <option value={''} key='_'>Send Email</option>
            {
              Object.keys(emailOptions).map(key => {
                return(
                  <option value={key} key={key}>
                    {emailOptions[key]}
                  </option>
                )
              })
            }
          </select>
        </div>
      )
    }
    return fields;
  }

  return(
    <div id='dynamic_fields_section'>
      {contactAndEmailFields()}
      <div className='dynamic_fields'>
        {dynamicFields()}
      </div>
    </div>
  )
}

export default DynamicMatchFields;
