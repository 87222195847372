import React from 'react';

const ZeroReferralInterruptor = (props) => {
  const {
    noteable,
    existingMatchId,
    setShowInterruptor,
    loadKillPopup,
    toggleHeader,
    close,
  } = props;

  function reloadKillPopup() {
    loadKillPopup();
    setShowInterruptor(0);
    toggleHeader();
  }

  function proceed() {
    setShowInterruptor(0);
    toggleHeader();
  }

  return (
    <div className='modal-body'>
      <h2>No referral fee is owned on this referral.</h2>
      <div className="existing-matches-container">
        {existingMatchId && (
          <>
            <p>We found the following match that may be related:</p>
            <a href={`/m/${existingMatchId}`} target="_blank">
              {existingMatchId}
            </a>
          </>
        )}
      </div>
      <p className="interrupt-prompt">How would you like to proceed?</p>
      <div className="interrupt-buttons-container">
        {noteable === 'lead' && (
          <input
            type="submit"
            value="Kill Lead"
            onClick={reloadKillPopup}
          />
        )}
        <input
          type="submit"
          value="Proceed"
          onClick={proceed}
        />
        <input
          type="submit"
          value="Cancel"
          onClick={close}
        />
      </div>
    </div>
  )
}

export default ZeroReferralInterruptor;
