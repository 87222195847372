class FollowUp extends React.Component{
  constructor(props){
    super(props);

    this.updateFollowUpDays = this.updateFollowUpDays.bind(this);
    this.set6AmTomorrow = this.set6AmTomorrow.bind(this);
    this.setAssignedToMe = this.setAssignedToMe.bind(this);
  }

  componentDidMount() {
    const overrideOptions = { timeFormat: 'h:mm TT' };
    $('#task_follow_up').attachDateTimePicker(5, this.props.updateFollowUp, overrideOptions);
  }

  formatDateString = function(date) {
    return(date.toLocaleString().replace(/:\d{2}\s{1}/g, ' ').replace(',', ''));
  }

  updateFollowUpDays(e) {
    const numDays = parseInt(e.currentTarget.value);

    if (isNaN(numDays) || numDays < 1) {
      this.props.updateFollowUp('');
    } else {
      const date = new Date();
      date.setDate(date.getDate() + numDays);
      date.setHours(6, 0, 0); // set to 6AM

      const dateString = this.formatDateString(date);
      this.props.updateFollowUp(dateString);
    }
  }

  set6AmTomorrow() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(6, 0, 0);

    const tomorrow6AmString = this.formatDateString(date);
    this.props.updateFollowUp(tomorrow6AmString);
  }

  setAssignedToMe(e) {
    const assignee = e.target.checked ? this.props.currentUserId : ''
    this.props.updateAssignedTo(assignee);
  }

  render() {
    const taskDaysOptions = ['', 7, 15, 30, 45, 60, 90, 120, 150, 180, 365];
    let dayNum = '';

    if (this.props.task.followUp) {
      // Get number of days until selected date and add it to the dropdown as the last item
      const timeUntil = new Date(this.props.task.followUp) - new Date();
      dayNum = Math.ceil(timeUntil / (1000 * 3600 * 24));
      if (!taskDaysOptions.includes(dayNum) && !isNaN(dayNum) && dayNum > 0) {
        taskDaysOptions.push(dayNum);
      }
    }

    return(
      <div id="followup_section">
        <b>Follow Up On: </b>
        <input
          id="task_follow_up"
          value={this.props.task.followUp}
          autoComplete="off"
          name="followUp"
          type="text"
          onChange={this.props.updateFollowUp}
          disabled={this.props.disableInput}
        />

        <span className="bold italic">or </span>
        <select
          value={dayNum}
          id="task_follow_up_days"
          onChange={this.updateFollowUpDays}
          disabled={this.props.disableInput}
        >
          {
            taskDaysOptions.map((key, index) => {
              return(
                <option value={key} key={index}>{key}</option>
              )
            })
          }
        </select>
        <span> days </span>
        <span className="bold italic">or </span>
        <button onClick={this.set6AmTomorrow} disabled={this.props.disableInput}>6AM Tomorrow</button>
        <br/>

        <Prioritization
          task={this.props.task}
          updateTaskState={this.props.updateTaskState}
        />

        <label className="bold" htmlFor="task_assign_to_me">Assign to Me: </label>
        <input
          type="checkbox"
          id="task_assign_to_me"
          checked={this.props.task.assignedTo == this.props.currentUserId}
          onChange={this.setAssignedToMe}
          disabled={this.props.disableInput}
        />

        <span className="bold italic"> or </span>
        <select
          name="assignedTo"
          value={this.props.task.assignedTo}
          onChange={this.props.updateAssignedTo}
          disabled={this.props.disableInput}
        >
          <option val='' key={0}></option>
          {
            this.props.flywheelUserOptions.map((key) => {
              return(
                <option value={key[1]} key={key[1]}>{key[0]}</option>
                )
              })
            }
        </select>
      </div>
    )
  }
}

const Prioritization = props => {
  if (!!props.task.prioritize) {
    let tag = 'Priority';
    let priorityOptions = ['Priority 1', 'Priority 2', 'Priority 3', 'Priority 4'];
    if (props.task.closing) {
      tag = 'Closing Priority';
      priorityOptions = ['Closing 1st Priority', 'Closing 2nd Priority', 'Closing 3rd Priority', 'Closing 4th Priority'];
    }

    return(
      <div id='priority'>
        <span className='bold italic'>{tag} </span>
        <select
          value={props.task.priority || 4}
          name='priority'
          id='task_priority'
          onChange={props.updateTaskState}
          disabled={props.disableInput}
        >
          {
            priorityOptions.map((key, index) => {
              const idx = index + 1;
              return(
                <option value={idx} key={idx}>{key}</option>
              )
            })
          }
        </select>
      </div>
    )
  } else {
    return null;
  }
}

export default FollowUp;
