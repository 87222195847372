const StatusSelector = props => {
  if (props.onAgent) {
    if (props.allowAgentStatusChange) {
      return(
        <div className="status-selector">
          <strong>Status</strong>
          <select
            name="status"
            value={props.value}
            onChange={e => props.updateNoteState(e)}
            disabled={props.disableInput}
          >
            <option value={''} key='_'></option>
            {
              props.noteStatusOptions.map((val, index) => {
                return(
                  <option value={val} key={index + '' + index}>
                    {val}
                  </option>
                )
              })
            }
          </select>
          {" (only select if you are changing the status)"}
        </div>
      )
    } else {
      return null;
    }
  }

  return(
    <div className="status-selector">
      <strong>Status</strong>
      <select
        name="status"
        value={props.value}
        onChange={e => props.updateNoteState(e)}
        disabled={props.disableInput}
      >
        <option value={''} key='_'>(no status)</option>
        {
          Object.keys(props.noteStatusOptions).map((key, collectionIndex) => {
            return(
              <optgroup label={key} key={key}>
                {
                  props.noteStatusOptions[key].map((val, index) => {
                    return(
                      <option value={val} key={collectionIndex + '' + index}>
                        {val}
                      </option>
                    )
                  })
                }
              </optgroup>
            )
          })
        }
      </select>
    </div>
  )
}

const Message = props => {
  return(
    <div className="note-message">
      <strong>{props.prompt || 'Message'}</strong>
      <textarea
        name='message'
        id='note_message'
        value={props.value}
        onChange={e => props.updateNoteState(e)}
        disabled={props.disableInput}
      />
    </div>
  )
}

const Footer = props => {
  let taskCreated = null;
  if (props.task && props.task.type && props.task.followUp) {
    taskCreated = (
      <span className='black' id="new_note_popup_task_type_holder">
        <span className='bold'>Creates: </span>
        <span className='italic' id='new_note_popup_task_type'>{props.task.type}</span>
      </span>
    )
  }

  let requalEmailCheckbox = null;
  let createText = 'Create';
  if (props.popupType === 'kill') {
    createText = 'Create and Kill Lead';
    requalEmailCheckbox = (
      <span id='kill_lead_send_requal_email'>
        <input
          type="checkbox"
          name="sendRequalEmail"
          value={props.send_requal_email}
          onChange={props.updateSendRequalEmail}
        />
        <label className='send_requal_email' htmlFor="send_requal_email">{' Keep sending requal email'}</label>
      </span>
    )
  } else if (props.popupType === 'remove_match') {
    createText = 'Create and Kill Match';
  }

  return(
    <div className='modal-footer'>
      {requalEmailCheckbox}
      <button
        className='btn btn-default'
        type='button'
        disabled={props.disableInput}
        onClick={props.close}>Cancel</button>
      <button
        className='btn btn-primary'
        type='submit'
        disabled={props.disableInput}
        onClick={props.submit}>{createText}</button>
        {taskCreated}
    </div>
  )
}

const Errors = ({ errors }) => {
  if (!!errors) {
    return(
      <div className="error_messages">
        {
          errors.map((error, key) => (
            <div key={key} className="error_message">{error}</div>
          ))
        }
      </div>
    )
  } else {
    return null;
  }
}

export { StatusSelector, Message, Footer, Errors };
