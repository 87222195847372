import ReactDOM from 'react-dom';
import React from 'react';

import MatchModalBody from './MatchModalBody';
import LeadModalBody from './LeadModalBody';
import { StatusSelector, Message, Footer, Errors } from './functional';
import FollowUp from './FollowUp';
import Fetch from '../../common/fetch';

const matchFields = [
  'agentCommission',
  'agentClosedAt',
  'agentListedAt',
  'listingPrice',
  'mlsNumber',
  'purchasePropertyAddress'
];

const additionalInfoFields = [
  'listingLinks',
  'listingStatus',
  'listingAgent',
  'soldRemovedAt',
  'soldPrice'
];

const closingInfoFields = [
  'mortgageLender',
  'loanOfficer',
  'loanAmount',
  'interestRate'
];
export default class NewNotePopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      clientFinancing: '',
      popup: {
        popupTitle: null,
        noteStatusOptions: null,
        disableInput: false,
        flywheelUserOptions: [],
        currentUser: null,
        popupType: this.props.popup_type,
        mortgageLenderOptions: []
      },
      note: {
        noteableType: this.props.noteable_type,
        noteableId: this.props.noteable_id,
        status: '',
        message: ''
      },
      additionalInfo: {
        listingLinks: '',
        listingStatus: '',
        listingAgent: '',
        soldRemovedAt: '',
        soldPrice: ''
      },
      match: {},
      lead: {},
      referralForm: {},
      sendEmail: '',
      task: {
        followUp: '',
        assignedTo: '',
        type: '',
        forceAssignee: false,
        requireFollowUp: false
      },
      checkDetails: {
        checkNumber: '',
        checkAmount: '',
        checkDate: ''
      },
      closingInfo: {
        mortgageLender: '',
        loanOfficer: '',
        loanAmount: '',
        interestRate: ''
      },
      allowAgentStatusChange: false,
      allowClosingsCollectables: false,
      sendRequalEmail: '',
      contact: '',
      closingsCollectables: {
        amountOwedTotal: '',
        badDebtReason: '',
        uncollectible: false,
        uncollectibleAmount: '',
      }
    }

    this.updateNoteState = this.updateNoteState.bind(this);
    this.updateTaskState = this.updateTaskState.bind(this);
    this.updateClosingsCollectables = this.updateClosingsCollectables.bind(this);
    this.updateMatchState = this.updateMatchState.bind(this);
    this.formatInput = this.formatInput.bind(this);
    this.fetchNote = this.fetchNote.bind(this);
    this.submit = this.submit.bind(this);
    this.submitPayload = this.submitPayload.bind(this);
    this.close = this.close.bind(this);
    this.getTaskBehavior = this.getTaskBehavior.bind(this);
    this.setInputEnabled = this.setInputEnabled.bind(this);
    this.updateFollowUp = this.updateFollowUp.bind(this);
    this.updateAssignedTo = this.updateAssignedTo.bind(this);
    this.updateCheckDetails = this.updateCheckDetails.bind(this);
    this.updateClosingInfo = this.updateClosingInfo.bind(this);
    this.updateStateByName = this.updateStateByName.bind(this);
    this.updateSendRequalEmail = this.updateSendRequalEmail.bind(this);
    this.loadKillPopup = this.loadKillPopup.bind(this);
    this.updateAdditionalInfo = this.updateAdditionalInfo.bind(this);
    this.toggleHeader = this.toggleHeader.bind(this);

    this.modalContent = this.modalContent.bind(this);
    this.modalBody = this.modalBody.bind(this);
    this.errorValidationFail = this.errorValidationFail.bind(this);
  }

  fetchNote() {
    const {
      popup: {
        popupType,
      },
      note: {
        noteableType,
        noteableId,
      }
    } = this.state;

    const url = '/notes/new_note_popup_data';

    const params = {
      noteableType,
      noteableId,
      popupType,
    };

    Fetch.get({ url, params })
      .then((data) => {
        this.setState(this.buildNewState(data));
        this.showPopup();
      })
  }

  buildNewState(data) {
    const {
      allowAgentStatusChange,
      allowClosingsCollectables,
      clientFinancing,
      lead_data,
      match,
      closingInfo,
      popup,
    } = data;

    const newState = {
      allowAgentStatusChange,
      allowClosingsCollectables,
      clientFinancing,
      popup: {
        ...this.state.popup,
        ...popup,
      },
    };

    if (match) {
      newState.match = match
    }

    if (lead_data) {
      newState.lead = lead_data
    }

    if (closingInfo) {
      newState.closingInfo = closingInfo;

      if (closingInfo.closingsCollectables) {
        console.log(closingInfo.closingsCollectables);
        newState.closingsCollectables = closingInfo.closingsCollectables;
      }
    }

    return newState;
  }

  showPopup() {
    const popup = document.getElementById('new_note_popup');

    if (popup) {
      popup.style.display = 'block';
    }
  }

  componentDidMount() {
    if (this.state.popup.popupType === 'remove_match') {
      this.setState({
        note: {
          ...this.state.note,
          status: 'User Removed Match',
        },
      })
    }

    this.fetchNote();
  }

  close() {
    ReactDOM.unmountComponentAtNode(document.getElementById('popup_holder'));
  }

  setInputEnabled(enabled) {
    const popup = Object.assign({}, this.state.popup, { disableInput: !enabled })
    this.setState({ popup: popup });
  }

  submitPayload() {
    const {
      additionalInfo,
      popup,
      note: {
        message,
        status,
        noteableType,
        noteableId,
      },
      task: {
        assignedTo,
        followUp,
        priority,
        type,
      },
      closingInfo,
      match,
      allowClosingsCollectables,
      closingsCollectables,
      sendRequalEmail,
      sendEmail,
      contact,
      checkDetails,
      clientFinancing,
    } = this.state;

    const payload = {
      note: {
        message,
        status,
        noteableType,
        noteableId,
      }
    }

    // Only send task for note popup when follow up is available
    if ($('#followup_section').is(':visible')) {
      payload.task = {
        assignedTo,
        followUp,
        priority,
        type,
      }
    }

    // Send this data only for matches
    if ($('#dynamic_fields_section').is(':visible')) {
      const payloadMatch = {};
      const payloadAdditionalInfo = {};
      const payloadClosingInfo = {};

      const fieldQuery = (field) => $(`#dynamic_fields_section [name=${field}]`);

      // Only submit Match, additionalInfo, and closingInfo data for dynamic fields that are visible

      matchFields.forEach((field) => {
        if (fieldQuery(field).is(':visible')) {
          payloadMatch[field] = match[field];
        }
      });

      additionalInfoFields.forEach((field) => {
        if (fieldQuery(field).is(':visible')) {
          payloadAdditionalInfo[field] = additionalInfo[field];
        }
      });

      closingInfoFields.forEach((field) => {
        if (fieldQuery(field).is(':visible')) {
          payloadClosingInfo[field] = closingInfo[field];
        }
      });

      payload.match = payloadMatch;
      payload.additionalInfo = payloadAdditionalInfo;
      payload.closingInfo = payloadClosingInfo;
      payload.sendEmail = sendEmail;
      payload.contact = contact;

      // Check details always appear together
      if ($('.check_details').is(':visible')) {
        payload.checkDetails = checkDetails;
      }

      if ($('input[name="clientFinancing"]').is(':visible')) {
        payload.clientFinancing = clientFinancing;
      }
    }

    if (popup.popupType === 'remove_match') {
      payload.match_id = this.state.match.id;
      payload.agent_id = this.state.match.agentId;
      payload.lead_id = this.state.match.leadId;
      payload.user_id = popup.currentUser.id;
    }

    if (popup.popupType === 'kill' && sendRequalEmail) {
      // note creator checks for presence of param
      payload.sendRequalEmail = true;
    }

    if (allowClosingsCollectables && closingsCollectables.amountOwedTotal) {
      payload.closingsCollectables = { ...closingsCollectables };
      payload.closingsCollectables.amountOwedTotal = parseFloat(payload.closingsCollectables.amountOwedTotal.replace(/[^0-9\.]+/g, ''));

      if (payload.closingsCollectables.uncollectibleAmount) {
        payload.closingsCollectables.uncollectibleAmount = parseFloat(payload.closingsCollectables.uncollectibleAmount.replace(/[^0-9\.]+/g, ''));
      }

      payload.closingsCollectables.source = 'match_note';
    }

    return payload;
  }

  errorValidationFail() {
    let fail = false;
    const errors = [];

    const {
      note: {
        noteableType,
      },
      closingsCollectables: {
        amountOwedTotal,
        badDebtReason,
        uncollectible,
        uncollectibleAmount,
      },
      allowClosingsCollectables,
    } = this.state;

    if (noteableType.toLowerCase() !== 'match' || !allowClosingsCollectables) {
      return false;
    }

    if (amountOwedTotal && !badDebtReason) {
      errors.push('Reason for Outstanding Balance: Please choose a reason');
      fail = true;
    }

    if (uncollectible && !uncollectibleAmount) {
      errors.push('Uncollectible Amount: Please enter an uncollectible amount');
      fail = true;
    }

    this.setState({ errors });

    return fail;
  }

  submit() {
    if (this.errorValidationFail()) {
      return;
    }

    this.setInputEnabled(false);
    const that = this;
    $.ajax({
      url: '/notes',
      method: 'POST',
      data: this.submitPayload(),
      success: function(response) {
        const errors = response.errors;

        if (errors === 'lead_matching_check_fail') {
          if (window.toggleMatchingTransitionErrorModal) {
            window.toggleMatchingTransitionErrorModal(true);
            return that.close();
          }
        }

        if (!!errors) {
          that.setState({ errors: errors });
        } else {
          that.close();
        }
      },
      complete: function() {
        if ($('#new_note_popup').is(':visible')) {
          that.setInputEnabled(true);
        }
      }
    });
  }

  getTaskBehavior() {
    this.setInputEnabled(false);

    const that = this;
    $.ajax({
      url: '/notes/task_behavior',
      method: 'GET',
      data: {
        note: this.state.note
      },
      success: function(response) {
        const task = Object.assign({}, that.state.task, response.task);
        that.setState({ task: task });

        // For forceAssignee if assignedTo not already set
        if (that.state.task.forceAssignee && !that.state.task.assignedTo) {
          const dummyEvent = { currentTarget: { name: 'assignedTo', value: that.state.task.forceAssignee } }
          that.updateTaskState(dummyEvent);
        }

        // Set requireFollowUp if followup not already set
        if (that.state.task.requireFollowUp && !that.state.task.followUp) {
          const date = new Date();
          date.setDate(date.getDate() + 1);
          date.setHours(6, 0, 0);

          const tomorrow6AmString = date.toLocaleString().replace(/:\d{2}\s{1}/g, ' ').replace(',', '');
          that.updateFollowUp(tomorrow6AmString);
        }
      },
      complete: function() {
        that.setInputEnabled(true);
      }
    });
  }

  updateNoteState(e) {
    const note = this.state.note;
    const key = e.currentTarget.name;

    note[key] = e.currentTarget.value;
    this.setState({ note: note });

    if (key == 'status') {
      this.getTaskBehavior();
    }
  }

  updateTaskState(e) {
    const task = this.state.task;
    const key = e.currentTarget.name;

    task[key] = e.currentTarget.value;
    this.setState({ task: task });
  }

  updateClosingsCollectables(e) {
    const { closingsCollectables, match } = this.state;
    const { name, value, checked } = e.currentTarget;

    if (name === 'uncollectible') {
      closingsCollectables[name] = checked;
    } else {
      closingsCollectables[name] = value;
    }

    if (name === 'amountOwedTotal' && value < match.receivables) {
      closingsCollectables.badDebtReason = '';
      closingsCollectables.uncollectible = false;
      closingsCollectables.uncollectibleAmount = null;
    }

    this.setState({ closingsCollectables })
  }

  updateMatchState(e, restrictions) {
    const match = this.state.match;
    const key = e.currentTarget.name;

    match[key] = this.formatInput(e.currentTarget.value, restrictions);
    this.setState({ match: match });
  }

  loadKillPopup() {
    const newState = this.state;
    newState.popup.popupType = 'kill'
    this.setState(newState, this.fetchNote)
  }

  formatInput(value, restrictions = null) {
    if (!restrictions) return value;

    if (restrictions.input == 'number') {
      value = value.replace(/[^0-9.]/g, '');
      value = value.replace(/(?<=\..*)\./g, ''); // replace all but first period
    }

    if (restrictions.maxLength) {
      value = value.substring(0, restrictions.maxLength)
    }

    return(value);
  }

  toggleHeader() {
    const header = document.querySelector('#new_note_popup .modal-header');
    if (header) {
      header.classList.toggle('hidden');
    }
  }

  updateFollowUp(e) {
    // DatePicker callback sends just the string value
    if (typeof e === 'string') {
      const dummyEvent = { currentTarget: { name: 'followUp', value: e } }
      this.updateTaskState(dummyEvent);
    } else if (typeof e === 'object') {
      this.updateTaskState(e);
    }
  }

  updateStateByName(e) {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    this.setState({ [name]: value })
  }

  updateAssignedTo(e) {
    const type = (typeof e);
    if (['number', 'string'].includes(type)) {
      const dummyEvent = { currentTarget: { name: 'assignedTo', value: e } }
      this.updateTaskState(dummyEvent);
    } else if (type === 'object') {
      this.updateTaskState(e);
    }
  }

  updateCheckDetails(e, restrictions) {
    const name = e.currentTarget.name;
    const value = this.formatInput(e.currentTarget.value, restrictions);

    const checkDetails = Object.assign({}, this.state.checkDetails, { [name]: value });
    this.setState({ checkDetails: checkDetails });
  }

  updateClosingInfo(e, restrictions) {
    const name = e.currentTarget.name;
    const value = this.formatInput(e.currentTarget.value, restrictions);

    const closingInfo = Object.assign({}, this.state.closingInfo, { [name]: value });
    this.setState({ closingInfo: closingInfo });
  }

  updateSendRequalEmail(e) {
    const { name, checked } = e.currentTarget;
    this.setState({ [name]: checked })
  }

  updateAdditionalInfo(e) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value
    const additionalInfo = Object.assign({}, this.state.additionalInfo, { [name]: value });

    this.setState({ additionalInfo: additionalInfo });
  }

  render() {
    return(
      <div id='new_note_popup' className='modal'>
        <input type="hidden" id="popup_data" />

        <div className='modal-dialog'>
          {this.modalContent()}
        </div>
      </div>
    );
  }

  modalContent() {
    if (!this.state.popup.popupTitle) {
      return(
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Loading...</h2>
          </div>
        </div>
      )
    } else {
      return(
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>
              {this.state.popup.popupTitle + ' '}
              <span className='italic'>
                {this.state.note.noteableType + ' #' + this.state.note.noteableId}
              </span>
            </h2>
          </div>
          {this.modalBody()}
        </div>
      )
    }
  }

  modalBody() {
    const {
      lead,
      note,
      task,
      match,
      popup,
      errors,
      contact,
      sendEmail,
      closingInfo,
      checkDetails,
      referralForm,
      additionalInfo,
      clientFinancing,
      allowAgentStatusChange,
      allowClosingsCollectables,
      sendRequalEmail,
      closingsCollectables,
    } = this.state;

    switch (note.noteableType.toLowerCase()) {
      case 'match':
        return(
          <MatchModalBody
            allowClosingsCollectables={allowClosingsCollectables}
            lead={lead}
            note={note}
            task={task}
            match={match}
            popup={popup}
            errors={errors}
            contact={contact}
            sendEmail={sendEmail}
            closingInfo={closingInfo}
            checkDetails={checkDetails}
            referralForm={referralForm}
            additionalInfo={additionalInfo}
            currentUser={popup.currentUser}
            clientFinancing={clientFinancing}
            flywheelUserOptions={popup.flywheelUserOptions}
            mortgageLenderOptions={popup.mortgageLenderOptions}
            closingsCollectables={closingsCollectables}
            close={this.close}
            submit={this.submit}
            loadKillPopup={this.loadKillPopup}
            toggleHeader={this.toggleHeader}
            updateFollowUp={this.updateFollowUp}
            updateNoteState={this.updateNoteState}
            updateTaskState={this.updateTaskState}
            updateMatchState={this.updateMatchState}
            updateAssignedTo={this.updateAssignedTo}
            updateStateByName={this.updateStateByName}
            updateClosingInfo={this.updateClosingInfo}
            updateClosingsCollectables={this.updateClosingsCollectables}
            updateCheckDetails={this.updateCheckDetails}
            updateAdditionalInfo={this.updateAdditionalInfo}
          />
        )
      case 'agent':
        return(
          <div className='modal-body'>
            <Errors
              errors={errors}
            />
            <StatusSelector
              disableInput={popup.disableInput}
              value={note.status}
              updateNoteState={this.updateNoteState}
              noteStatusOptions={popup.noteStatusOptions}
              allowAgentStatusChange={allowAgentStatusChange}
              onAgent={true}
            />
            <Message
              disableInput={popup.disableInput}
              value={note.message}
              updateNoteState={this.updateNoteState}
            />
            <Footer
              disableInput={popup.disableInput}
              close={this.close}
              submit={this.submit}
            />
          </div>
        )
      case 'lead':
        return(
          <LeadModalBody
            lead={lead}
            note={note}
            task={task}
            popup={popup}
            errors={errors}
            currentUser={popup.currentUser}
            sendRequalEmail={sendRequalEmail}
            flywheelUserOptions={popup.flywheelUserOptions}

            close={this.close}
            submit={this.submit}
            toggleHeader={this.toggleHeader}
            loadKillPopup={this.loadKillPopup}
            updateFollowUp={this.updateFollowUp}
            updateNoteState={this.updateNoteState}
            updateTaskState={this.updateTaskState}
            updateAssignedTo={this.updateAssignedTo}
            updateSendRequalEmail={this.updateSendRequalEmail}
          />
        )
      case 'referral':
        return(
          <div className='modal-body'>
            <Errors
              errors={errors}
            />
            <Message
              disableInput={popup.disableInput}
              value={note.message}
              updateNoteState={this.updateNoteState}
            />
            <FollowUp
              disableInput={popup.disableInput}
              task={task}
              updateTaskState={this.updateTaskState}
              flywheelUserOptions={popup.flywheelUserOptions}
              updateFollowUp={this.updateFollowUp}
              updateAssignedTo={this.updateAssignedTo}
              currentUserId={popup.currentUser.id}
            />
            <Footer
              disableInput={popup.disableInput}
              close={this.close}
              submit={this.submit}
              task={task}
            />
          </div>
        )
      default:
        return(
          <div className='modal-body'>
            <h2>No body HTML for {note.noteableType}</h2>
          </div>
        )
    }
  }
}
