import React from 'react';
import  { formatCurrency } from '../util/textMasks';

const options = [
  'Below Minimum Payment',
  'Collections',
  'Negotiations',
  'Not Owed Yet',
  'Payment Plan',
  'Wrong Referral Fee Percentage',
  'Other'
];

const currencies = [
  'USD',
  'CAD',
];

const ClosingsCollectables = (props) => {
  const {
    disableInput,
    closingsCollectables: {
      amountOwedTotal,
      badDebtReason,
      uncollectible,
      uncollectibleAmount,
      currency,
    },
    onChange,
    match: {
      agentCommision,
      receivables: receivablesApplied,
    }
  } = props;

  const amountOwedRemaining = Math.max(
    parseFloat(amountOwedTotal.replace(/[^0-9\.]+/g, '') || 0)
    - parseFloat(receivablesApplied),
    0
  );

  const balanceRemains = receivablesApplied > amountOwedTotal

  return (
    <div id="closings-collectables-section">
      <div className="closings-header bold">
        Closings Only
      </div>
      <div className="closings-fields">
        <div className="closings-field">
          <p><b>{`Receivables Applied: ${receivablesApplied}`}</b></p>
          <p><b>{`Amount Owed Remaining: ${amountOwedRemaining}`}</b></p>
        </div>
        <div className="closings-field">
          <span>Estimated Amount Owed Total:</span>
          <input
            type="text"
            name='amountOwedTotal'
            disabled={disableInput}
            onChange={onChange}
            value={formatCurrency(amountOwedTotal)}
          />
        </div>
        <div className="closings-field">
          <span>Reason for Outstanding Balance:</span>
          <select
            name='badDebtReason'
            disabled={disableInput || balanceRemains}
            onChange={onChange}
            value={badDebtReason}
          >
            <option value=""></option>
            {
              options.map((opt, key) => (
                <option key={key} value={opt}>{opt}</option>
              ))
            }
          </select>
        </div>
        <div className="closings-field">
          <span>Uncollectible?</span>
          <input
            type="checkbox"
            name="uncollectible"
            checked={uncollectible}
            disabled={disableInput || balanceRemains}
            onChange={onChange}
          />

        </div>
        <div className="closings-field">
          <span>Uncollectible Amount:</span>
          <input
            type="text"
            name="uncollectibleAmount"
            disabled={disableInput || !uncollectible}
            onChange={onChange}
            value={formatCurrency(uncollectibleAmount)}
          />
        </div>
        <div className="closings-field">
          <span>Currency:</span>
          <select
            name='currency'
            disabled={disableInput}
            onChange={onChange}
            value={currency}
          >
            <option value=""></option>
            {
              currencies.map((opt, key) => (
                <option key={key} value={opt}>{opt}</option>
              ))
            }
          </select>
        </div>
      </div>
    </div>
  )
}

export default ClosingsCollectables;
